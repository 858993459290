// const apiEndpoint = 'https://placidea-api.docker.localhost/';
const apiEndpoint = 'https://dev-placidea-api.staging.stonecode.io/';
// const apiEndpoint = 'https://preproduction-placidea-api.staging.stonecode.io/';
// const apiEndpoint = 'https://api.placidea.tech/';

/* eslint-disable sort-keys */
if (!window.APP_CONFIG) {
	window.APP_CONFIG = {
		services: [
			{
				'@id': 'partition',
				'@type': 'service',
				'@urn': '$:registry:service:partition',
				'id': 'partition',
				'name': 'Partitions',
				'description': '',
				'configurations': {
					'api_endpoint': apiEndpoint + 'partition',
				},
			},
			{
				'@id': 'rh',
				'@type': 'service',
				'@urn': '$:registry:service:rh',
				'id': 'rh',
				'name': 'RH',
				'description': '',
				'configurations': {
					'api_endpoint': apiEndpoint + 'rh',
				},
			},
			{
				'@id': 'vehicle',
				'@type': 'service',
				'@urn': '$:registry:service:vehicle',
				'id': 'vehicle',
				'name': 'Vehicles',
				'description': '',
				'configurations': {
					'api_endpoint': apiEndpoint + 'vehicle',
				},
			},
			{
				'@id': 'fleet',
				'@type': 'service',
				'@urn': '$:registry:service:fleet',
				'id': 'fleet',
				'name': 'Fleet',
				'description': '',
				'configurations': {
					'api_endpoint': apiEndpoint + 'fleet',
				},
			},
			{
				'@id': 'directory',
				'@type': 'service',
				'@urn': '$:registry:service:directory',
				'id': 'directory',
				'name': 'Directory',
				'description': '',
				'configurations': {
					'api_endpoint': apiEndpoint + 'directory',
				},
			},
			{
				'@id': 'rights',
				'@type': 'service',
				'@urn': '$:registry:service:rights',
				'id': 'rights',
				'name': 'Rights',
				'description': '',
				'configurations': {
					'api_endpoint': apiEndpoint + 'rights',
				},
			},
			{
				'@id': 'sales',
				'@type': 'service',
				'@urn': '$:registry:service:sales',
				'id': 'sales',
				'name': 'Sales',
				'description': '',
				'configurations': {
					'api_endpoint': apiEndpoint + 'sales',
				},
			},
			{
				'@id': 'intervention',
				'@type': 'service',
				'@urn': '$:registry:service:intervention',
				'id': 'intervention',
				'name': 'Intervention',
				'description': '',
				'configurations': {
					'api_endpoint': apiEndpoint + 'intervention',
				},
			},
			{
				'@id': 'invoice',
				'@type': 'service',
				'@urn': '$:registry:service:invoice',
				'id': 'invoice',
				'name': 'Invoice',
				'description': '',
				'configurations': {
					'api_endpoint': apiEndpoint + 'invoice',
				},
			},
			{
				'@id': 'time',
				'@type': 'service',
				'@urn': '$:registry:service:time',
				'id': 'time',
				'name': 'Time',
				'description': '',
				'configurations': {
					'api_endpoint': apiEndpoint + 'time',
				},
			},
			{
				'@id': 'notification',
				'@type': 'service',
				'@urn': '$:registry:service:notification',
				'id': 'notification',
				'name': 'Notification',
				'description': '',
				'configurations': {
					'api_endpoint': apiEndpoint + 'notification',
				},
			},
			{
				'@id': 'auditer',
				'@type': 'service',
				'@urn': '$:registry:service:auditer',
				'id': 'auditer',
				'name': 'Auditer',
				'description': '',
				'configurations': {
					'api_endpoint': apiEndpoint + 'auditer',
				},
			},
			{
				'@id': 'graph',
				'@type': 'service',
				'@urn': '$:registry:service:graph',
				'id': 'graph',
				'name': 'Graph',
				'description': '',
				'configurations': {
					'api_endpoint': apiEndpoint + 'graph',
				},
			},
			{
				'@id': 'mercure',
				'@type': 'service',
				'@urn': '$:registry:service:mercure',
				'id': 'mercure',
				'name': 'Mercure',
				'description': '',
				'configurations': {
					'api_endpoint': apiEndpoint + '.well-known/mercure',
				},
			},
		],
	};
}

const AppConfig = window.APP_CONFIG;

export default AppConfig;
